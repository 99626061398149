import React from "react"
import PropTypes from "prop-types"
import "./style.scss"

function Modal ({ project, onClose }) {
    const handleBackgroundClick = (e) => {
        if (e.target.className === "modal") {
            onClose();
        }
    };

    return (
        <div className="modal" onClick={handleBackgroundClick}>
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>

                <div className="modal-container">
                    <div className="modal-grid">
                        <div className="modal-grid-item images-content">
                            {project.images.slice(1).map((image, index) => (
                                <img 
                                    key={index}
                                    src={image.src}
                                    alt={image.alt}
                                    className="modal-image"
                                />
                            ))}
                        </div>

                        <div className="modal-grid-item text-content">
                            <h2 className="modal-title">{project.titre}</h2>

                            <p className="date-info">
                                <i className="fa-light fa-calendar calendar-icon"></i>
                                <i>Année: {project.année}</i>
                            </p>

                            <p className="tech">
                                <i className="fa-light fa-laptop-code"></i>
                                {project.technologies.join(', ')}
                            </p>

                            <h3 className="modal-title-cat">Description</h3>
                            <p>{project.description}</p>

                            <h3 className="modal-title-cat">Problématiques</h3>
                            <p>{project.problématiques}</p>

                            <h3 className="modal-title-cat">Lien</h3>
                            <a href={project.lien} target="_blank" rel="noopener noreferrer" className="git-button">
                                <i className="fa-brands fa-github anchor-icon" ></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    project: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
} 

export default Modal